.scroll-position {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 8px;
  height: 8px;
  background-color: transparentize($color-light, 0.5);
  border-radius: 4px;
  opacity: 0;
  transition: all 0.1s ease-out;
  z-index: 1001;

  &.show {
    opacity: 1;
  }
}
