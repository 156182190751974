// Resets
:focus {
  outline: none;
  outline-color: transparent;
}

body {
  fill: currentColor;
  background-color: $background-color;
  @include unselectable-class();
}

svg {
  pointer-events: none;
}

// Centerers
.center-vertical {
  @include position(absolute, 50% null null null);
  @include transform(translateY(-50%));
}

.center-horizontal {
  @include position(absolute, null null null 50%);
  @include transform(translateX(-50%));
}

.center {
  @include position(absolute, 50% null null 50%);
  @include transform(translate(-50%, -50%));
}

.center-vertical-relative {
  @include position(relative, 50% null null null);
  @include transform(translateY(-50%));
}

.center-horizontal-relative {
  @include position(relative, null null null 50%);
  @include transform(translateX(-50%));
}

.center-relative {
  @include position(relative, 50% null null 50%);
  @include transform(translate(-50%, -50%));
}

// Displayers
.none {
  display: none;
}

.hidden {
  visibility: hidden;
}

.opacity-none {
  opacity: 0;
}

.invisible {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.image-holder {
  img.img-res {
    width: 100%;
    max-width: 100%;
  }
}

// Floats
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

// Text aligns
.ta-left {
  text-align: left;
}

.ta-right {
  text-align: right;
}

.ta-center {
  text-align: center;
}

// Cursors
.pointer {
  cursor: pointer !important;
}

.nopointer {
  cursor: default !important;
}

// Flex
.flex-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  .child {
    order: 1;
  }
}

.flex-column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .child {
    order: 1;
  }
}

// Animations
@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  to {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.rotating {
  -webkit-animation: rotating 1s linear infinite;
  -moz-animation: rotating 1s linear infinite;
  -ms-animation: rotating 1s linear infinite;
  -o-animation: rotating 1s linear infinite;
  animation: rotating 1s linear infinite;
}

@-webkit-keyframes rotating-centered {
  from {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    -o-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
  to {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    -o-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }
}

@keyframes rotating-centered {
  from {
    -ms-transform: translate(-50%, -50%) rotate(360deg);
    -moz-transform: translate(-50%, -50%) rotate(360deg);
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    -o-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
  to {
    -ms-transform: translate(-50%, -50%) rotate(0deg);
    -moz-transform: translate(-50%, -50%) rotate(0deg);
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    -o-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }
}

.rotating-centered {
  -webkit-animation: rotating-centered 1s linear infinite;
  -moz-animation: rotating-centered 1s linear infinite;
  -ms-animation: rotating-centered 1s linear infinite;
  -o-animation: rotating-centered 1s linear infinite;
  animation: rotating-centered 1s linear infinite;
}

// Modal
.modal {
  background-color: rgba(#FFF, .95);
  .modal-dialog {
    margin-top: 8%;
  }
}
