.company-brand-selector {
  width: 100%;
  @include flex-row(flex-start, inherit);

  .scroller {
    position: relative;
    max-height: 260px;
    overflow: hidden;

    .scroll {
      position: relative;
      transition: transform 0.2s ease-out;
      padding-bottom: 0;
    }
  }

  .holder {
    padding: 2px;
    border: 1px solid $color-light-border;
    margin-top: 8px;
    margin-right: 8px;
    border-radius: 4px;
    flex-grow: 1;
  }

  .an-item {
    @include flex-row(flex-start, center);
    padding: 6px 14px;
    margin: 2px;
    background-color: lighten($color-lightest, 10);
    border-radius: 2px;
    cursor: pointer;

    &:first-of-type {
      margin-top: 4px;
    }

    img {
      border-radius: 50%;
      width: 32px;
      height: 32px;
      margin-right: 8px;
    }

    p {
      margin: 0;
      padding: 0;
      color: $color-darkest;
      font-size: 0.9rem;
      font-weight: 400;
      @include single-line();
    }

    &.selected {
      background-color: $color-darkest;

      p {
        color: $background-color;
      }
    }
  }
}
