.card {
  border: $card-border;
  border-radius: 4px;
  padding: 20px;

  &.high {
    box-shadow: $card-high-shadow;
  }

  &.higher {
    box-shadow: $card-higher-shadow;
  }

  &.highest {
    box-shadow: $card-highest-shadow;
  }

}

.an-overdraw {
  .icon {
    position: absolute;
    @include center();
    @include icon-with-dimensions(128px, 128px, $color-light);
  }
}
