#login-footer {
  z-index: 1;
  position: absolute;
  bottom: 0;
  width: 100%;

  .zer-logo {
    height: size-calculator(43px, 1);
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  p {
    font-size: 12px;
    text-align: center;
    color: $color-dark;
  }
}
