@font-face {
  font-family: 'Open Sans';
  src: url('#{$fonts-url}/OpenSans-Regular-webfont.woff');
  font-weight: normal; // 400
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('#{$fonts-url}/OpenSans-Italic-webfont.woff');
  font-weight: normal; // 400
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('#{$fonts-url}/OpenSans-Bold-webfont.woff');
  font-weight: bold; // 700
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('#{$fonts-url}/OpenSans-BoldItalic-webfont.woff');
  font-weight: bold; // 700
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('#{$fonts-url}/OpenSans-Light-webfont.woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('#{$fonts-url}/OpenSans-LightItalic-webfont.woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('#{$fonts-url}/OpenSans-Semibold-webfont.woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('#{$fonts-url}/OpenSans-SemiboldItalic-webfont.woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('#{$fonts-url}/OpenSans-ExtraBold-webfont.woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('#{$fonts-url}/OpenSans-ExtraBoldItalic-webfont.woff');
  font-weight: 900;
  font-style: italic;
}
