.btn {
  min-width: 120px;
  height: 36px;
  border: $button-default-border;
  border-radius: 4px;
  background-color: $button-default-background;
  box-shadow: $button-default-shadow;
  cursor: pointer;

  &:hover {
    background-color: darken($button-default-background, $button-secondary-dark);
  }

  &:active {
    background-color: darken($button-default-background, $button-secondary-darker);
  }

  &.noshadow {
    box-shadow: none;
  }

  &:disabled {
    filter: blur(1px) saturate(0.2) brightness(1.1);
    cursor: default;
  }

  &.primary {
    border: none;
    background-color: $button-primary-background;
    color: $button-primary-color;

    &:hover:enabled {
      background-color: darken($button-primary-background, $button-primary-dark);
    }

    &:active:enabled {
      background-color: darken($button-primary-background, $button-primary-darker);
    }

    &.success {
      background-color: $color-success;
      color: $button-primary-color;

      &:hover:enabled {
        background-color: darken($color-success, $button-primary-dark);
      }

      &:active:enabled {
        background-color: darken($color-success, $button-primary-darker);
      }
    }

    &.warning {
      background-color: $color-warning;
      color: $button-primary-color;

      &:hover:enabled {
        background-color: darken($color-warning, $button-primary-dark);
      }

      &:active:enabled {
        background-color: darken($color-warning, $button-primary-darker);
      }
    }

    &.error {
      background-color: $color-error;
      color: $button-primary-color;

      &:hover:enabled {
        background-color: darken($color-error, $button-primary-dark);
      }

      &:active:enabled {
        background-color: darken($color-error, $button-primary-darker);
      }
    }
  }

  &.secondary {
    border: none;
    background-color: $button-secondary-background;
    color: $button-secondary-color;
    border: $button-secondary-border;

    &:hover:enabled {
      background-color: lighten($button-secondary-border-color, $button-secondary-dark);
      border-color: darken($button-secondary-border-color, $button-secondary-dark);
      color: $button-secondary-background;
    }

    &:active:enabled {
      background-color: lighten($button-secondary-border-color, $button-secondary-darker);
      border-color: darken($button-secondary-border-color, $button-secondary-darker);
      color: $button-secondary-background;
    }

    &.success {
      border-color: $color-success;
      color: $color-success;

      &:hover:enabled {
        background-color: lighten($color-success, $button-secondary-dark);
        border-color: darken($color-success, $button-secondary-dark);
        color: $button-secondary-background;
      }

      &:active:enabled {
        background-color: lighten($color-success, $button-secondary-darker);
        border-color: darken($color-success, $button-secondary-darker);
        color: $button-secondary-background;
      }
    }

    &.warning {
      border-color: $color-warning;
      color: $color-warning;

      &:hover:enabled {
        background-color: lighten($color-warning, $button-secondary-dark);
        border-color: darken($color-warning, $button-secondary-dark);
        color: $button-secondary-background;
      }

      &:active:enabled {
        background-color: lighten($color-warning, $button-secondary-darker);
        border-color: darken($color-warning, $button-secondary-darker);
        color: $button-secondary-background;
      }
    }

    &.error {
      border-color: $color-error;
      color: $color-error;

      &:hover:enabled {
        background-color: lighten($color-error, $button-secondary-dark);
        border-color: darken($color-error, $button-secondary-dark);
        color: $button-secondary-background;
      }

      &:active:enabled {
        background-color: lighten($color-error, $button-secondary-darker);
        border-color: darken($color-error, $button-secondary-darker);
        color: $button-secondary-background;
      }
    }
  }

  &.large {
    height: 48px;
    font-size: 90%;
  }

  &.small {
    height: 30px;
    font-size: 76%;
  }
}
