input[role="form"] {
  margin: 0;
  font-size: 1rem;
  font-weight: normal;
  color: $input-text;
  background-color: $input-background;

  &.small {
    height: 32px;
  }

  &.tiny {
    height: 28px;
  }
}

.input-with-placeholder {
  position: relative;
  border-radius: 4px;
  border: $input-border;
  background-color: $input-background;
  box-sizing: border-box;

  [role="form"] {
    width: 100%;
    box-sizing: border-box;
    padding: 8px 16px;
    border: none;
    background-color: transparent;

    &.pad {
      padding: 8px 46px 8px 16px;
    }

    &:disabled {
      color: $color-light;
      border-radius: 4px;
      background-color: darken($input-background, 5);
    }
  }

  .placeholder {
    @include position(absolute, 50% null null null);
    @include transform(translateY(-50%));
    left: 14px;
    padding: 0 3px;
    margin: 0;
    font-size: 90%;
    font-weight: 400;
    color: $input-placeholder;
    background-color: transparent;
    transition: all 0.05s ease-in-out;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
    cursor: text;

    &.small {
      font-size: 70%;
      top: 0;
      background-color: $background-color;
    }

    &.small-animated {
      font-size: 70%;
      top: 0;
      background-color: $background-color;
    }
  }

  textarea + .placeholder {
    top: 20px;
  }

  .icon {
    right: 8px;
    @include position(absolute, 50% null null null);
    @include transform(translateY(-50%));
    @include icon-with-dimensions(32px, 32px, $input-border);
  }

  .status {
    position: absolute;
    bottom: -15px;
    left: 0;
    height: 16px;
    width: 100%;
    transition: all 0.05s ease-in-out;

    .status-icon {
      display: block;
      position: absolute;
      @include icon-with-dimensions(16px, 16px, $input-border);
    }

    .message {
      line-height: 16px;
      font-size: 0.7rem;
      position: absolute;
      top: 2px;
      left: 20px;
    }
  }

  &.success {
    border-color: $color-success;
    svg {
      g, path {
        fill: $color-success;
      }
    }
    .placeholder {
      color: $color-success;
    }
    .status {
      .message {
        color: $color-success;
      }
    }
  }

  &.warning {
    border-color: $color-warning;
    svg {
      g, path {
        fill: $color-warning;
      }
    }
    .placeholder {
      color: $color-warning;
    }
    .status {
      .message {
        color: $color-warning;
      }
    }
  }

  &.error {
    border-color: $color-error;
    svg {
      g, path {
        fill: $color-error;
      }
    }
    .placeholder {
      color: $color-error;
    }
    .status {
      .message {
        color: $color-error;
      }
    }
  }

  &.small {
    .icon, .icon > svg {
      width: 24px;
      height: 24px;
    }
    .placeholder {
      font-size: 90%;
      &.small, &.small-animated {
        font-size: 70%;
      }
    }
    input[role="form"] {
      font-size: 100%;
      &.pad {
        padding: 8px 35px 8px 16px;
      }
    }
  }

  &.tiny {
    .icon, .icon > svg {
      width: 18px;
      height: 18px;
    }
    .placeholder {
      font-size: 72%;
      &.small, &.small-animated {
        font-size: 50%;
      }
    }
    input[role="form"] {
      font-size: 80%;
      &.pad {
        padding: 8px 30px 8px 16px;
      }
    }
  }

  p {
    width: auto !important;
  }
}
