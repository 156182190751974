#holi-header {
  position: relative;
  background-color: $background-color;
  height: 42px;
  z-index: 9999;
}

#header-holi-logo {
  height: 28px;
  width: calculated-width(377px, 176px, 28px);
}
